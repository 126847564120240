<template>
  <main>
    <div
      class="relative pt-16 pb-32 flex content-center items-center justify-center md:mb-10"
      style="min-height: 60vh;"
    >
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style='background-image: url("//images.unsplash.com/photo-1506521781263-d8422e82f27a?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1600&amp;q=80");'
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-75 bg-black"
        ></span>
      </div>
      <div class="container relative mx-auto">
        <div class="items-center flex flex-wrap">
          <div class="w-full lg:w-2/3 px-4 mx-auto text-center pt-10">
            <div class="text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl">
              <h5 class="inline slider-text">{{ textContent }}</h5><span :class="['cursor', { 'blink': cursorBlink }]">&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="pt-4 md:pt-8">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap lg:px-20 font-light">
          <div class="w-full p-3">
            <h1 class="font-medium text-xl md:text-3xl pb-1">Looking for a parking space?</h1>
            <p class="text-sm text-gray-700">Select your city to find a parking parking space nearby</p>
          </div>
          <template v-if="cities.length">
            <CityListItem
              v-for="city in cities"
              :key="city.id"
              :city="city"
              @select="redirectToParkingSpaces"
            />
          </template>
          <template v-else>
            <CityListItemLoader
              v-for="i in Array(4)"
              :key="i"
            />
          </template>
        </div>
      </div>
    </section>

    <section class="py-4 md:py-8">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap lg:px-20 font-light">
          <div class="w-full p-2 md:p-3">
            <h2 class="font-medium text-xl md:text-2xl pb-1">View & pay your parking bill online</h2>
            <p class="text-sm text-gray-700">Find your vehicle & pay the bill online to avoid queue</p>
          </div>
          <div class="w-full p-2 md:p-3">
            <div class="relative">
              <div class="flex text-gray-500">
                <a
                  href=""
                  class="text-md font-medium bg-white py-2 px-8 rounded-t-md border border-gray-200 mr-1"
                  :class="{ 'border-b-0': searchType == 'parking', 'bg-gray-50 text-gray-400': searchType != 'parking' }"
                  :disabled="searchType == 'parking'"
                  @click.prevent="selectSearchType('parking')"
                >
                  Parking
                </a>
                <a
                  href=""
                  class="text-md font-medium bg-white py-2 px-8 rounded-t-md border border-gray-200"
                  :class="{ 'border-b-0': searchType == 'subscription', 'bg-gray-50 text-gray-400': searchType != 'subscription' }"
                  :disabled="searchType == 'subscription'"
                  @click.prevent="selectSearchType('subscription')"
                >
                  Subscription
                </a>
              </div>
            </div>
            <VehicleSearch
              v-model="form"
              is-one-line
              :vehicleTypes="filteredVehicleTypes"
              :cities="cities"
              :loading="searching"
              class="-mt-px rounded-tl-none"
              @submit="redirectToSearchPage"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="pb-4 md:pb-8">
      <div class="container mx-auto px-4">
        <div class="lg:px-20 font-light">
          <div class="w-full p-2 md:p-3 mb-5 md:mb-8">
            <h2 class="font-medium text-2xl pb-2">Why choose parknsure?</h2>
            <p class="text-md text-gray-700 roboto">
              At Parknsure, we're passionate about providing top-notch parking solutions that exceed our customers' expectations. Experience the difference with us and see why so many choose us for their parking needs.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap lg:px-20 font-light mb-6 md:mb-12">
          <div class="w-full md:w-1/3 px-2 md:pr-12">
            <div class="bg-gray-200 py-3 px-5 inline-block mb-3 md:mb-5 rounded-lg">
              <span class="material-symbols-outlined text-5xl">workspace_premium</span>
            </div>
            <h3 class="font-medium text-lg pb-2">Premium quality service</h3>
            <p>Discover unmatched quality, professionalism, and dependability with our end-to-end ownership, staffing, and operation of all parking facilities.</p>
          </div>
          <div class="w-full md:w-1/3 px-2 md:px-6">
            <div class="bg-gray-200 py-3 px-5 inline-block mb-3 md:mb-5 rounded-lg">
              <span class="material-symbols-outlined text-5xl">verified_user</span>
            </div>
            <h3 class="font-medium text-lg pb-2">24/7 Security</h3>
            <p>Travel with peace of mind knowing that we prioritize your vehicle's safety with well-lit premises and 24/7 surveillance, allowing you to journey worry-free.</p>
          </div>
          <div class="w-full md:w-1/3 px-2 md:pl-12">
            <div class="bg-gray-200 py-3 px-5 inline-block mb-3 md:mb-5 rounded-lg">
              <span class="material-symbols-outlined text-5xl">credit_card_off</span>
            </div>
            <h3 class="font-medium text-lg pb-2">No-hidden charges</h3>
            <p>Enjoy a straightforward parking experience with no hidden charges, as we prioritize transparency and strive to make your parking experience hassle-free.</p>
          </div>
        </div>
        <div class="flex flex-wrap lg:px-20 font-light roboto-slab">
          <div class="w-full md:w-4/12 px-2 md:px-3 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow rounded-md"
            >
              <div class="p-4 flex-auto">
                <p class="mt-2 text-sm font-medium text-gray-500">
                  Onboarded
                </p>
                <div
                  class="pt-3 text-center inline-flex items-center justify-center w-full h-24"
                >
                  <h6 class="text-5xl font-semibold analytics-count">{{ info.parkingarea || '' }}</h6>
                </div>
                <p class="mb-2 text-xl font-medium text-gray-900">
                  Parking areas
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-4/12 px-2 md:px-3 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow rounded-md"
            >
              <div class="p-4 flex-auto">
                <p class="mt-2 text-sm font-medium text-gray-500">
                  Serving
                </p>
                <div
                  class="pt-3 text-center inline-flex items-center justify-center w-full h-24"
                >
                  <h6 class="text-5xl font-semibold analytics-count">{{ info.slots || '' }}</h6>
                </div>
                <p class="mb-2 text-xl font-medium text-gray-900">
                  Parking slots
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-4/12 px-2 md:px-3 text-center">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow rounded-md"
            >
              <div class="p-4 flex-auto">
                <p class="mt-2 text-sm font-medium text-gray-500">
                  Till now
                </p>
                <div
                  class="pt-3 text-center inline-flex items-center justify-center w-full h-24"
                >
                  <h6 class="text-5xl font-semibold analytics-count">{{ totalParking || '' }}</h6>
                </div>
                <p class="mb-2 text-xl font-medium text-gray-900">
                  Vehicles parked
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VehicleSearch from "@/components/VehicleSearch"
import CityListItem from "@/components/CityListItem"
import CityListItemLoader from "@/components/CityListItemLoader"
import loadCities from "@/mixins/loadCities"
import loadVehicleTypes from "@/mixins/loadVehicleTypes"

export default {
  components: {
    VehicleSearch,
    CityListItem,
    CityListItemLoader
  },
  mixins: [loadCities, loadVehicleTypes],
  data() {
    const info = JSON.parse(window.localStorage.getItem('parking-info') || '{}');
    const cityObj = JSON.parse(window.localStorage.getItem('city') || '{}')
    const city = cityObj?.id || ""
    const form = JSON.parse(window.localStorage.getItem('parking_search') || `{ "city": "${city}", "type": "", "number": "" }`)
    return {
      form,
      info,
      cities: [],
      vehicleTypes: [],
      textArray: ["Unlock hassle-free parking", "Smart parking, smarter solutions", "Drive in, park with confidence"],
      textContent: '',
      charIndex: 0,
      textArrayIndex: 0,
      cursorBlink: false,
      searching: false,
      searchType: 'parking'
    }
  },
  computed: {
    totalParking () {
      return this.info.parking
    },
    filteredVehicleTypes () {
      return this.searchType === 'parking' ? this.vehicleTypes : this.vehicleTypes.filter(({ subscriptionAllowed }) => subscriptionAllowed)
    }
  },
  watch: {
    searchType () {
      this.form.type = ''
    }
  },
  created () {
    this.loadParkingInfo()
    this.loadVehicleTypes()
    this.loadAvailableCities()
  },
  mounted () {
    this.typeContent()
  },
  methods: {
    typeContent () {
      if (this.charIndex <= this.textArray[this.textArrayIndex].length - 1) {
        this.cursorBlink = false;
        this.textContent += this.textArray[this.textArrayIndex].charAt(this.charIndex);
        this.charIndex++;
        setTimeout(this.typeContent, 120);
      } else {
        this.cursorBlink = true;
        setTimeout(this.eraseContent, 1000);
      }
    },
    eraseContent () {
      if (this.charIndex > 0) {
        this.cursorBlink = false;
        this.textContent = this.textArray[this.textArrayIndex].slice(0, this.charIndex - 1);
        this.charIndex--;
        setTimeout(this.eraseContent, 80);
      } else {
        this.cursorBlink = true;
        this.textArrayIndex++;
        if (this.textArrayIndex > this.textArray.length - 1) {
          this.textArrayIndex = 0;
        }
        setTimeout(this.typeContent, 1000);
      }
    },
    loadParkingInfo () {
      if (!this.info.parkingarea || !this.info.expiry || this.info.expiry <= new Date().getTime()) {
        fetch(`${process.env.VUE_APP_API_ROOT}parking-info`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors"
          })
          .then(response => response.json())
          .then(({ success, data }) => {
            if (success) {
              data.expiry = new Date().getTime() + (24 * 60 * 60 * 1000); // 1 day
              window.localStorage.setItem('parking-info', JSON.stringify(data));
              this.info = data
            }
          });
      }
    },
    selectSearchType (type) {
      this.searchType = type
    },
    redirectToParkingSpaces (city) {
      window.localStorage.setItem('city', JSON.stringify(city))
      this.$router.push({ name: 'spaces' })
    },
    redirectToSearchPage () {
      this.searching = true
      this.$router.push({ path: `/${this.searchType}` })
    },
  },
}
</script>

<style scoped>
  .slider-text {
    background: -webkit-linear-gradient(285deg, #42d392 22%, #647eff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .material-symbols-outlined {
    background: -webkit-linear-gradient(315deg, #42d392 25%, #647eff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .analytics-count {
    background: -webkit-linear-gradient(315deg, #647eff 25%, #d264ff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .cursor {
    display: inline-block;
    width: 3px;
    margin-left: 4px;
    background: #fff;
  }

  .blink {
    animation: blink 0.8s ease-in-out infinite;
  }

  @keyframes blink {
    0%, 100% {
      background: #fff;
    }
    40%, 50% {
      background: transparent;
    }
  }
</style>
